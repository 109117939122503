import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  IconButton,
  Button,
  AccordionActions,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TextField,
  Box,
  Grid,
} from '@mui/material';
import Editor from '@monaco-editor/react';
import { useState } from 'react';
import { useFieldArray, useFormContext, Controller } from 'react-hook-form';
import { apiUrl } from '../utils/constants';
import { UpdateForm } from '../types';
import { fetchWithAuth } from 'utils';

export const FunctionRepeater = () => {
  const [expanded, setExpanded] = useState<string | false>(false);
  const { control, watch } = useFormContext<UpdateForm>();

  const { fields, insert, remove } = useFieldArray({
    control,
    keyName: 'fieldId',
    name: 'functions',
  });

  const handleAccordionChange =
    (panel: string) => (_event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const removeFunction = async (id: number | undefined, index: number) => {
    try {
      const response = await fetchWithAuth(`${apiUrl}/chat-function/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.status !== 200) {
        throw new Error('Something went wrong!');
      }
      remove(index);
    } catch (error) {
      console.error(error);
    }
  };

  const functionWatch = watch('functions');

  return (
    <>
      {fields &&
        fields.length > 0 &&
        fields.map((field, index) => {
          return (
            <Accordion
              key={field.fieldId}
              expanded={expanded === `panel${index}`}
              onChange={handleAccordionChange(`panel${index}`)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
              >
                <Typography sx={{ fontStyle: 'italic' }}>𝑓 </Typography>
                {functionWatch && functionWatch[index] && functionWatch[index].name && (
                  <Typography sx={{ marginLeft: '1rem' }}>{functionWatch[index].name}</Typography>
                )}
              </AccordionSummary>
              <AccordionDetails key={field.fieldId}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name={`functions.${index}.name`}
                      control={control}
                      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                        <TextField
                          label="Name"
                          type="text"
                          onChange={onChange}
                          onBlur={onBlur}
                          error={!!error}
                          helperText={error ? error.message : null}
                          value={value}
                          InputLabelProps={{ shrink: true }}
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name={`functions.${index}.description`}
                      control={control}
                      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                        <TextField
                          onChange={onChange}
                          onBlur={onBlur}
                          error={!!error}
                          helperText={error ? error.message : null}
                          value={value}
                          multiline
                          rows={3}
                          label="Description"
                          type="text"
                          InputLabelProps={{ shrink: true }}
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      component="span"
                      sx={{ display: 'inline-block', marginBottom: '.5rem' }}
                    >
                      Parameters
                    </Typography>
                    <Controller
                      name={`functions.${index}.parameters`}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Editor
                          value={value ? value : '{}'}
                          defaultLanguage="json"
                          height="200px"
                          options={{
                            minimap: { enabled: false },
                          }}
                          onChange={(value) => {
                            onChange(value);
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      component="span"
                      sx={{ display: 'inline-block', marginBottom: '.5rem' }}
                    >
                      Code
                    </Typography>
                    <Controller
                      control={control}
                      name={`functions.${index}.code`}
                      render={({ field: { value, onChange } }) => (
                        <Editor
                          value={value}
                          defaultLanguage="javascript"
                          height="500px"
                          options={{
                            minimap: { enabled: false },
                          }}
                          onChange={(value) => {
                            onChange(value);
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
              <AccordionActions>
                <Button type="button" onClick={() => removeFunction(field.id, index)}>
                  Remove
                </Button>
              </AccordionActions>
            </Accordion>
          );
        })}
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <IconButton aria-label="add function" onClick={() => insert(fields.length, {})}>
          <AddCircleOutlineIcon />
        </IconButton>
      </Box>
    </>
  );
};
