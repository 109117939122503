import { useState } from 'react';

export const useLocalStorage: (
  key: string,
  defaultValue: string,
) => [string, (value: string) => void] = (key: string, defaultValue: string) => {
  const [value, setValue] = useState(() => {
    const storedValue = localStorage.getItem(key);
    return storedValue !== null ? storedValue : defaultValue;
  });
  const setStoredValue = (value: string) => {
    setValue(value);
    localStorage.setItem(key, value);
  };
  return [value, setStoredValue];
};
