import { HTMLAttributes, Dispatch, SetStateAction } from 'react';
import { DateRange, SelectRangeEventHandler } from 'react-day-picker';
import { cn } from 'utils';
import { Calendar } from 'components/shadcn/calendar';
import { Popover, PopoverContent, PopoverTrigger } from 'components/shadcn/popover';
import { Calendar as CalendarIcon } from 'lucide-react';
import { Button } from 'components/shadcn/button';

export const DateRangePicker = ({
  className,
  dateRange,
  setDateRange,
  open,
  setOpen,
}: HTMLAttributes<HTMLDivElement> & {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  dateRange: DateRange | undefined;
  setDateRange: SelectRangeEventHandler;
}) => {
  return (
    <div className={cn('grid gap-2', className)}>
      <Popover open={open}>
        <PopoverTrigger asChild>
          <Button
            id="date"
            className="sr-only"
            style={{ padding: 0, height: 0 }}
            variant={'outline'}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
          </Button>
        </PopoverTrigger>
        <PopoverContent
          onPointerDownOutside={() => setOpen(false)}
          className="w-auto p-0"
          align="start"
        >
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={dateRange?.from}
            selected={dateRange}
            onSelect={setDateRange}
            numberOfMonths={2}
            // onDayClick={() => setSelectValue(undefined)}
            className="rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background"
          />
        </PopoverContent>
      </Popover>
    </div>
  );
};
