import { useContext, FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from 'contexts';

interface ProtectedRouteProps {
  children: JSX.Element;
}

export const ProtectedRoute: FC<ProtectedRouteProps> = ({ children }) => {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!authContext?.isAuthenticated) {
      navigate('/dashboard/login');
    }
  }, []);

  return children;
};
