import { ThemeOptions } from '@mui/material';

export const defaultThemeOptions: ThemeOptions = {
  direction: 'ltr',
  palette: {
    primary: {
      main: '#12B76A',
      500: '#12B76A',
      600: '#039855',
    },
    grey: {
      100: '#F2F4F7',
      600: '#475467',
    },
  },
  shape: {
    borderRadius: 8,
  },
  typography: {
    fontFamily: 'Rubik',
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiTextField: {
      variants: [
        {
          props: {
            variant: 'outlined',
          },
          style: {
            boxShadow: '0px 1px 2px 0px #1018280D;',
            '& .MuiOutlinedInput-root': {
              borderColor: 'white',
            },
          },
        },
      ],
    },
  },
};

export const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3000/message/democracy';
