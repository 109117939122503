import { RemoveCircle } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { apiUrl } from '../utils/constants';

import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { UpdateForm } from '../types';
import { fetchWithAuth } from 'utils';

export const DynamicSystemPromptRepeater = () => {
  const { control } = useFormContext<UpdateForm>();

  const { fields, insert, remove } = useFieldArray({
    control,
    keyName: 'fieldId',
    name: 'dynamicSystemPrompts',
  });

  const removePrompt = async (id: number | undefined, index: number) => {
    if (id) {
      try {
        const response = await fetchWithAuth(`${apiUrl}/dynamic-prompt/${id}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (response.status !== 200) {
          throw new Error('Something went wrong!');
        }
      } catch (error) {
        console.error(error);
      }
    }
    remove(index);
  };

  return (
    <Stack p={2} spacing={3}>
      <Typography variant="h6">Dynamic Prompts</Typography>
      <Stack direction="row" alignItems="center">
        <Typography flexGrow={1}>
          Dynamic prompts are system prompts that vary based on the conversation metadata.
        </Typography>
        <Button
          color="success"
          variant="outlined"
          onClick={() => insert(fields.length, { prompt: '', condition: '', zIndex: 0 })}
          disableElevation
          disableRipple
        >
          <Typography align="center">＋ Add</Typography>
        </Button>
      </Stack>
      <Stack spacing={2} alignItems="center" justifyContent="center">
        <Box flexGrow={1} width="100%">
          {fields?.map((field, index) => (
            <Accordion key={field.fieldId}>
              <AccordionSummary>
                <Typography alignSelf="center">Dynamic System Prompt {index + 1}</Typography>
                <Box flexGrow={1} />
                <IconButton aria-label="delete" onClick={() => removePrompt(field.id, index)}>
                  <RemoveCircle />
                </IconButton>
              </AccordionSummary>
              <AccordionDetails>
                <Stack spacing={2}>
                  <Stack spacing={2} direction="row">
                    <Controller
                      name={`dynamicSystemPrompts.${index}.condition`}
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          label="Condition"
                          {...field}
                          id={`dynamicSystemPrompts[${index}].condition`}
                          type="text"
                          name={`dynamicSystemPrompts[${index}].condition`}
                          defaultValue={field.value}
                        />
                      )}
                      control={control}
                      defaultValue={field.condition}
                    />
                    <Controller
                      render={({ field }) => (
                        <TextField
                          label="Z-Index"
                          {...field}
                          id={`dynamicSystemPrompts[${index}].zIndex`}
                          type="number"
                          name={`dynamicSystemPrompts[${index}].zIndex`}
                          defaultValue={field.value}
                        />
                      )}
                      name={`dynamicSystemPrompts.${index}.zIndex`}
                      control={control}
                      defaultValue={field.zIndex}
                    />
                  </Stack>
                  <Controller
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        label="Prompt"
                        {...field}
                        rows={4}
                        multiline
                        id={`dynamicSystemPrompts[${index}].prompt`}
                        type="text"
                        name={`dynamicSystemPrompts[${index}].prompt`}
                        defaultValue={field.value}
                      />
                    )}
                    name={`dynamicSystemPrompts.${index}.prompt`}
                    control={control}
                    defaultValue={field.prompt}
                  />
                </Stack>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Stack>
    </Stack>
  );
};
