import { Card, Color, Divider, Link, Stack, Typography, useTheme } from '@mui/material';
import { Message, Sender } from './types';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import remarkGfm from 'remark-gfm';

export const MessageCard = ({
  message,
  showSources = false,
}: {
  message: Message;
  showSources: boolean;
}) => {
  const theme = useTheme();
  return (
    <Card
      elevation={0}
      paddingX={2}
      paddingY="12px"
      component={Stack}
      gap={2}
      sx={{
        background:
          message.sender === Sender.User
            ? `linear-gradient(84.12deg, ${
                (theme.palette.primary as unknown as Color)[500]
              } -3.89%, ${(theme.palette.primary as unknown as Color)[600]} 106.48%)`
            : theme.palette.grey[100],
      }}
    >
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        components={{
          p: ({ children }) => (
            <Typography
              color={
                message.sender === Sender.User
                  ? theme.palette.common.white
                  : theme.palette.common.black
              }
            >
              {children}
            </Typography>
          ),
          a: ({ href, children }) => (
            <Link href={href} target="_blank" rel="noopener noreferrer" display="inline-block">
              <Typography color={theme.palette.grey[600]} fontWeight={500}>
                {children}
              </Typography>
            </Link>
          ),
        }}
      >
        {message.content || ' '}
      </ReactMarkdown>
      {showSources && message.sources && message.content.length > 125 && (
        <>
          <Divider />
          <Stack gap={1}>
            {message.sources.map((source, index) => (
              <Stack key={index} direction="row" gap={1}>
                <Typography variant="body2" color={theme.palette.grey[600]} display="inline-block">
                  {index + 1}
                </Typography>
                <Link
                  href={source.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  display="inline-block"
                  minWidth={0}
                >
                  <Typography variant="body2" color={theme.palette.grey[600]} noWrap minWidth={0}>
                    {source.title}
                  </Typography>
                </Link>
              </Stack>
            ))}
          </Stack>
        </>
      )}
    </Card>
  );
};
