import { Button } from 'components/shadcn/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'components/shadcn/dialog';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/shadcn/table';
import dayjs from 'dayjs';
import { ChevronLeft, Eye, MessageCircle } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Conversation, Message } from 'types';
import { apiUrl, fetchWithAuth } from 'utils';

const Conversations = () => {
  const { chatId } = useParams();
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [selectedMetadata, setSelectedMetadata] = useState<unknown>(null);
  const [selectedMessages, setSelectedMessages] = useState<Message[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchConversations = async () => {
      try {
        const response = await fetchWithAuth(`${apiUrl}/conversation/${chatId}`);
        const data = await response.json();
        setConversations(data);
      } catch (error) {
        console.error('Error fetching conversations:', error);
      }
    };

    fetchConversations();
  }, [chatId]);

  return (
    <div className="mx-auto py-6 px-4 lg:px-6 lg:max-w-screen-lg flex-grow self-start box-border w-full">
      <div className="flex flex-row gap-2 justify-start items-center mb-10">
        <Button variant="link" size="icon" onClick={() => navigate(-1)}>
          <ChevronLeft className="h-4 w-4" />
        </Button>
        <h1 className="text-4xl font-black text-secondary">CONVERSATIONS</h1>
      </div>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Username</TableHead>
              <TableHead>Number of Messages</TableHead>
              <TableHead>Last Message Time</TableHead>
              <TableHead>Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {conversations.map((conversation) => (
              <TableRow key={conversation.id}>
                <TableCell>{conversation.username}</TableCell>
                <TableCell>{conversation.messages.length}</TableCell>
                <TableCell>
                  {dayjs(conversation.messages[conversation.messages.length - 1].createdAt).format(
                    'DD/MM/YYYY HH:mm',
                  )}
                </TableCell>
                <TableCell>
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => setSelectedMetadata(conversation.metadata)}
                    aria-label="View Metadata"
                  >
                    <Eye className="h-4 w-4" />
                  </Button>
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => setSelectedMessages(conversation.messages)}
                    aria-label="View Messages"
                  >
                    <MessageCircle className="h-4 w-4" />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>

      <Dialog open={!!selectedMetadata} onOpenChange={() => setSelectedMetadata(null)}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Conversation Metadata</DialogTitle>
            <DialogDescription>
              <pre>{JSON.stringify(selectedMetadata, null, 2)}</pre>
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button variant="outline" onClick={() => setSelectedMetadata(null)}>
              Close
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Dialog open={!!selectedMessages?.length} onOpenChange={() => setSelectedMessages([])}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Conversation Messages</DialogTitle>
            <div className="overflow-auto max-h-[500px]">
              {selectedMessages.map((m) => (
                <div key={m.id} className="mb-4">
                  <p className="capitalize font-medium">{m.sender.toLowerCase()}</p>
                  <p className="text-sm text-gray-600">
                    {new Date(m.createdAt || '').toLocaleString()}
                  </p>
                  <p className="mt-1 whitespace-pre-wrap">{m.content}</p>
                </div>
              ))}
            </div>
          </DialogHeader>
          <DialogFooter>
            <Button variant="outline" onClick={() => setSelectedMessages([])}>
              Close
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Conversations;
