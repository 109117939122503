import { Typography, Link } from '@mui/material';

export const Credits = () => (
  <Typography textAlign="center">
    By{' '}
    <Link underline="none" href="https://sofi.coop" target="_blank">
      sofi.coop
    </Link>
  </Typography>
);
