import { Button } from 'components/shadcn/button';
import { useEffect, useState, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChatMetadata, Role } from 'types';
import { apiUrl, fetchWithAuth } from 'utils';
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Plus, MoreHorizontal, Trash2, ExternalLink } from 'lucide-react';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'components/shadcn/dropdown-menu';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/shadcn/table';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogTrigger,
} from 'components/shadcn/dialog';
import { AuthContext } from 'contexts';

type ChatRow = Pick<ChatMetadata, 'id' | 'name' | 'inputTokens' | 'outputTokens' | 'totalMessages'>;

export const ChatList = () => {
  const dialogTriggerRef = useRef<HTMLButtonElement | null>(null);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = useState({});
  const [chats, setChats] = useState<ChatRow[]>([]);
  const [selectedChatId, setSelectedChatId] = useState<string | null>(null);
  const { role } = useContext(AuthContext);
  const navigate = useNavigate();

  const columns: ColumnDef<ChatRow>[] = [
    {
      accessorKey: 'id',
      header: 'ID',
      cell: ({ row }) => (
        <div className="flex items-center font-mono gap-2">
          {row.getValue('id')}
          <Button variant="ghost" size="icon" onClick={() => navigate(`/${row.getValue('id')}`)}>
            <ExternalLink className="h-4 w-4" />
          </Button>
        </div>
      ),
    },
    {
      accessorKey: 'name',
      header: () => <div>Name</div>,
      cell: ({ row }) => <div>{row.getValue('name')}</div>,
    },
    {
      accessorKey: 'inputTokens',
      header: () => <div className="text-right">Input Tokens</div>,
      cell: ({ row }) => {
        return <div className="text-right font-medium">{row.getValue('inputTokens')}</div>;
      },
    },
    {
      accessorKey: 'outputTokens',
      header: () => <div className="text-right">Output Tokens</div>,
      cell: ({ row }) => {
        return <div className="text-right font-medium">{row.getValue('outputTokens')}</div>;
      },
    },
    {
      accessorKey: 'totalMessages',
      header: () => <div className="text-right">Total Messages</div>,
      cell: ({ row }) => {
        return <div className="text-right font-medium">{row.getValue('totalMessages')}</div>;
      },
    },
    {
      id: 'actions',
      header: () => <div>Actions</div>,
      cell: ({ row }) => {
        const { id } = row.original;

        return (
          <div>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="h-8 w-8 p-0">
                  <span className="sr-only">Open menu</span>
                  <MoreHorizontal className="h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="start">
                <DropdownMenuLabel hidden>Actions</DropdownMenuLabel>
                <DropdownMenuItem onClick={() => navigate(`/dashboard/${id}/conversations`)}>
                  View Conversations
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => navigate(`/dashboard/edit/${id}`)}>
                  Edit
                </DropdownMenuItem>
                {role === Role.ADMIN && (
                  <>
                    <DropdownMenuItem onClick={() => onCreateNewChat(id)}>
                      Duplicate
                    </DropdownMenuItem>
                    <DropdownMenuItem onClick={() => navigate(`/dashboard/cost-summary/${id}`)}>
                      Cost Summary
                    </DropdownMenuItem>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem
                      onClick={() => handleDeleteClick(id)}
                      className="text-destructive focus:bg-destructive focus:text-destructive-foreground flex justify-between"
                    >
                      Delete
                      <Trash2 className="h-4 w-4 ml-2" />
                    </DropdownMenuItem>
                  </>
                )}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    const fetchChats = async () => {
      try {
        const response = await fetchWithAuth(`${apiUrl}/chat-metadata`);
        const jsonData = await response.json();
        setChats(jsonData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchChats();
  }, []);

  const onCreateNewChat = (chatId?: string) => {
    let url = '/dashboard/create';
    if (chatId) {
      url += `?duplicateChatId=${chatId}`;
    }
    navigate(url);
  };

  const handleDeleteClick = (chatId: string) => {
    setSelectedChatId(chatId);
    if (dialogTriggerRef.current) {
      dialogTriggerRef.current.click();
    }
  };

  const handleClose = () => {
    document.getElementById('close-dialog-button')?.click(); // TODO: Find a better way to close the dialog
  };

  const handleDeleteConfirm = async () => {
    if (selectedChatId) {
      try {
        await fetchWithAuth(`${apiUrl}/chat-metadata/${selectedChatId}`, {
          method: 'DELETE',
        });
        setChats(chats.filter((chat) => chat.id !== selectedChatId));
        handleClose();
      } catch (error) {
        console.error('Error deleting chat:', error);
      }
    }
  };

  const table = useReactTable({
    data: chats,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
      pagination: {
        pageSize: 100,
        pageIndex: 0,
      },
    },
  });

  return (
    <div className="mx-auto py-6 px-4 lg:px-6 lg:max-w-screen-lg flex-grow self-start box-border w-full">
      <div className="flex flex-row mb-10 items-center justify-between">
        <h1 className="text-4xl font-black text-secondary">CHATS</h1>
        {role === Role.ADMIN && (
          <Button variant="secondary" color="success" onClick={() => onCreateNewChat()}>
            <Plus className="mr-2 h-4 w-4" /> Create New Chat
          </Button>
        )}
      </div>
      <div className="w-full">
        <div className="rounded-md border">
          <Table>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead key={header.id}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(header.column.columnDef.header, header.getContext())}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length} className="h-24 text-center">
                    No results.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </div>
      <Dialog>
        <DialogTrigger asChild ref={dialogTriggerRef} style={{ display: 'none' }}>
          <Button />
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Are you sure you want to delete this chatbot?</DialogTitle>
            <DialogDescription>This action cannot be undone</DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button variant="outline" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="destructive" onClick={handleDeleteConfirm} autoFocus>
              Confirm
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};
