import { PaletteColorOptions } from '@mui/material';

export enum ChatModel {
  GPT_35 = 'GPT_35',
  GPT_35_16K = 'GPT_35_16K',
  GPT_4 = 'GPT_4',
  GPT_4_AZURE = 'GPT_4_AZURE',
  GPT_4_TURBO = 'GPT_4_TURBO',
  GPT_4O = 'GPT_4O',
  GPT_4O_2024_08_06 = 'GPT_4O_2024_08_06',
}

export enum AnswersModality {
  TEXT = 'TEXT',
  VOICE = 'VOICE',
  TEXT_AND_VOICE = 'TEXT_AND_VOICE',
}
export enum Role {
  ADMIN = 'ADMIN',
  EDITOR = 'EDITOR',
}
export interface Conversation {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  messages: Message[];
  username: string;
  metadata: unknown;
}

export interface User {
  id: string;
  email: string;
  role: Role;
  chats: Pick<ChatMetadata, 'id' | 'name' | 'inputTokens' | 'totalMessages' | 'outputTokens'>[];
}

export interface DynamicSystemPrompt {
  id?: number;
  prompt: string;
  condition: string;
  zIndex: number;
}

export interface ChatMetadata {
  firstMessage: string;
  id: string;
  name: string;
  direction: Direction;
  colors: PaletteColorOptions;
  textFieldLabel: string;
  showSources: boolean;
  messagesInMemory: number;
  inputTokens: number;
  outputTokens: number;
  totalMessages: number;
  conversations: [
    {
      messages: {
        sender: 'USER' | 'ASSISTANT';
        content: string;
      }[];
    },
  ];
  costs?: number | null;
}

export interface Message {
  id?: string;
  createdAt?: string;
  sender: Sender;
  content: string;
  sources?: Source[];
}

export interface Source {
  title: string;
  url: string;
}

export enum Sender {
  User = 'user',
  Assistant = 'assistant',
}

export enum Direction {
  LTR = 'LTR',
  RTL = 'RTL',
}

export type jwtToken = string | null;

export interface ChatFunction {
  id?: number;
  name?: string;
  description?: string;
  parameters?: string;
  code?: string;
  chatId?: string;
}

export interface UpdateForm {
  name: string;
  dynamicSystemPrompts?: DynamicSystemPrompt[];
  systemPrompt: string;
  whatsappPrompt?: string;
  firstMessage: string;
  messagesInMemory: number;
  openaiKey: string;
  twilioAccountSid: string;
  twilioAuthToken: string;
  model: ChatModel;
  direction: Direction;
  colors: string;
  temperature: number;
  showSources: boolean;
  functions?: ChatFunction[];
}
