import { Box, Card, Stack } from '@mui/material';
import { Button } from 'components/shadcn/button';
import { Conversation } from 'components';
import { MessageCircle } from 'lucide-react';
import { UpdateChatForm } from 'pages/dashboard';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { fetchWithAuth } from 'utils';
import { apiUrl } from 'utils/constants';
import { useNavigate } from 'react-router-dom';

export const EditChat = () => {
  const [name, setName] = useState('');

  const { id } = useParams();

  const navigate = useNavigate();

  const fetchName = async (id: string) => {
    const response = await fetchWithAuth(`${apiUrl}/chat-metadata/${id}`);
    const jsonData = await response.json();
    setName(jsonData.name);
  };

  useEffect(() => {
    try {
      if (!id) {
        setName('unknown');
        return;
      }
      fetchName(id);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  });

  return (
    <div className="py-6 px-4">
      <div className="flex flex-row mb-2 px-8 items-center justify-between">
        <h1 className="text-4xl font-black text-secondary">{name}</h1>
        <Button variant="secondary" color="success" onClick={() => navigate(`/${id}`)}>
          <MessageCircle className="mr-2 h-4 w-4" /> Chat with Bot
        </Button>
      </div>
      <Stack gap={2} direction="row" p={4} flexGrow={1}>
        <Box flex={2}>
          <UpdateChatForm />
        </Box>
        <Box flex={1}>
          <Card
            sx={{
              borderRadius: 2,
              position: 'sticky',
              top: 32,
              maxHeight: 'calc(100vh - 64px)',
              height: '100%',
              overflowY: 'auto',
            }}
            variant="outlined"
          >
            <Conversation playgroundMode />
          </Card>
        </Box>
      </Stack>
    </div>
  );
};
