import { Button } from 'components/shadcn/button';
import { DollarSign, ChevronLeft } from 'lucide-react';
import { useEffect, useState } from 'react';
import { ChatMetadata } from 'types';
import { apiUrl } from 'utils';
import { DateRangePicker } from 'components';
import dayjs from 'dayjs';
import { fetchWithAuth } from 'utils';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/shadcn/select';
import { Card, CardContent, CardHeader, CardTitle } from 'components/shadcn/card';
import { DateRange } from 'react-day-picker';

export const ChatCostSummary = () => {
  const navigate = useNavigate();
  const [chat, setChat] = useState<ChatMetadata>();
  const { id } = useParams();
  const [rangeOpen, setRangeOpen] = useState(true);
  const [selectValue, setSelectValue] = useState<string | undefined>('thisMonth');
  const currentDate = new Date();
  const lastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1);
  const [dateRange, setDateRange] = useState<DateRange | undefined>({
    from: dayjs(currentDate).startOf('month').toDate(),
    to: dayjs(currentDate).endOf('month').toDate(),
  });

  const selectDateOptions = [
    {
      value: 'thisMonth',
      dates: {
        from: dayjs(currentDate).startOf('month').toDate(),
        to: dayjs(currentDate).endOf('month').toDate(),
      },
      label: 'This Month',
    },
    {
      value: 'lastMonth',
      dates: {
        from: dayjs(lastMonth).startOf('month').toDate(),
        to: dayjs(lastMonth).endOf('month').toDate(),
      },
      label: 'Last Month',
    },
    {
      value: 'thisYear',
      dates: {
        from: new Date(currentDate.getFullYear(), 0),
        to: new Date(currentDate.getFullYear(), 11, 31),
      },
      label: `${currentDate.getFullYear()}`,
    },
    {
      value: 'lastYear',
      dates: {
        from: new Date(currentDate.getFullYear() - 1, 0),
        to: new Date(currentDate.getFullYear() - 1, 11, 31),
      },
      label: `${currentDate.getFullYear() - 1}`,
    },
    {
      value: 'today',
      dates: {
        from: currentDate,
        to: currentDate,
      },
      label: 'Today',
    },
  ];

  useEffect(() => {
    const fetchChat = async () => {
      if (dateRange?.from === undefined) {
        return;
      }
      const formattedFrom = dayjs(dateRange.from).format('YYYY-MM-DD');
      const formattedTo = dateRange.to ? dayjs(dateRange.to).format('YYYY-MM-DD') : formattedFrom;

      try {
        const response = await fetchWithAuth(
          `${apiUrl}/chat-metadata/${id}?messagesDateGte=${formattedFrom}&messagesDateLte=${formattedTo}`,
        );
        const jsonData = await response.json();
        const costsResponse = await fetchWithAuth(
          `${apiUrl}/chat-metadata/${id}/costs?messagesDateGte=${formattedFrom}&messagesDateLte=${formattedTo}`,
        );
        const costsData = await costsResponse.json();
        jsonData.costs = costsData.costs;
        setChat(jsonData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchChat();
  }, [dateRange]);

  useEffect(() => {
    if (selectValue === 'custom-period') {
      setRangeOpen(true);
    }
  }, [selectValue]);

  return (
    <div className="mx-auto px-4 py-6 lg:px-6 lg:max-w-screen-lg flex-grow self-start box-border w-full">
      <div className="flex flex-row mb-10 items-center justify-between">
        <div className="flex flex-row gap-2 justify-start items-center">
          <Button variant="link" size="icon" onClick={() => navigate(-1)}>
            <ChevronLeft className="h-4 w-4" />
          </Button>
          <h1 className="text-4xl font-black text-secondary">COST SUMMARY</h1>
          <h2 className="text-3xl text-secondary">
            - <i>{chat?.name || chat?.id}</i>
          </h2>
        </div>
        <div>
          <Select
            onValueChange={(value) => {
              if (value !== 'custom-period') {
                setDateRange(selectDateOptions.find((item) => item.value === value)?.dates);
              }
              setSelectValue(value);
            }}
            value={selectValue}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select Period">
                {selectValue === 'custom-period'
                  ? dateRange?.from &&
                    (dateRange.to ? (
                      <>
                        {dayjs(dateRange.from).format('MMM DD, YYYY')}
                        <span> - </span>
                        {dayjs(dateRange.to).format('MMM DD, YYYY')}
                      </>
                    ) : (
                      <>{dayjs(dateRange.from).format('MMM DD, YYYY')}</>
                    ))
                  : selectDateOptions.find((item) => item.value === selectValue)?.label}
              </SelectValue>
            </SelectTrigger>
            <SelectContent position="popper">
              {selectDateOptions.map((option) => (
                <SelectItem key={option.value} value={option.value}>
                  {option.label}
                </SelectItem>
              ))}
              <SelectItem value="custom-period">Custom Period</SelectItem>
            </SelectContent>
          </Select>
          {selectValue === 'custom-period' && (
            <DateRangePicker
              open={rangeOpen}
              setOpen={setRangeOpen}
              dateRange={dateRange}
              setDateRange={setDateRange}
            />
          )}
        </div>
      </div>
      <div className="flex flex-row items-center justify-between space-y-0 pb-2">
        <Card style={{ width: '11.45rem' }}>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">AI Cost</CardTitle>
            <DollarSign className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">
              {chat?.costs?.toFixed(2) ?? 'No message costs found'}
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};
